import { createName, dataSource } from "../../utils";
const label = "文本";
const name = "yes-text";

export default {
  icon: "yes-icon-text",
  label,
  name,
  rule() {
    return {
      type: name,
      // field: uniqueId(),
      title: label,
      info: "",
      props: {
        dataSource:"",
        textValue: ""
      },
    };
  },
  props() {
    return [
      createName(),
      dataSource(),
      {
        type: "input",
        field: "textValue",
        title: "文本",
        props: {
          type: "textarea",
          rows: 8,
        },
      },
      {
        type: "input",
        field: "textStyle",
        title: "样式",
        value:'',
        props: {
          type: "textarea",
          rows: 8,
        },
      },
      {
        type: "yesStruct",
        field: "clickEvent",
        title: "点击事件",
        props: {
          valueType : 'function'
        }
      },
    ];
  },
};
