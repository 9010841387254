import { createName } from '../../utils';
import uniqueId from '@form-create/utils/lib/unique';
import { getOptions } from "../../utils/classCss";
const name = 'yes-div';
const label = '容器';

export default {
  icon: 'yes-icon-div',
  name,
  label,
  drag: true,
  inside: true,
  mask: false,
  rule() {
    return {
      type: "yes-div",
      name: uniqueId(),
      props: {
        hiddenInit: false,
        backImg: false,
        backShowType: 'repeat',
        flexDirection: 'column',
        justifyValue: 'none',
        alignValue: 'none',
        coverType: 'all',
        backImgUrl: '',
        wrap: false,
        scrollShow: false
      },
      children: [],
    };
  },
  props() {
    return [
      createName(),
      {
        type: 'select', field: 'position', title: '位置',
        options: [
          { label: '自动', value: 'adaptive' },
          { label: '上', value: 'top' },
          { label: '下', value: 'bottom' },
          { label: '左', value: 'left' },
          { label: '右', value: 'right' }]
      },
      { type: 'input', field: 'zIndex', title: '层级' },
      { type: 'input', field: 'widthDesigner', title: '设计宽度(在设计页面显示宽度)' },
      { type: 'input', field: 'width', title: '宽度' },
      { type: 'input', field: 'widthMin', title: '最小宽度' },
      { type: 'input', field: 'heightDesigner', title: '设计高度(在设计页面显示高度)' },
      { type: 'input', field: 'height', title: '高度' },
      { type: 'input', field: 'heightMin', title: '最小高度' },
      {
        type: 'select',
        field: 'wrap',
        title: '自动换行',
        options: [
          { label: '开启', value: true },
          { label: '关闭', value: false }
        ]
      },
      { type: 'input', field: 'lg', title: '自动占比( >= 1200px )' },
      { type: 'input', field: 'md', title: '自动占比( 768px ~ 1199px )' },
      { type: 'input', field: 'xs', title: '自动占比( < 768px )' },
      { type: 'switch', field: 'isFix', title: '是否固定悬浮' },
      { type: 'input', field: 'marginTopValue', title: '外边距-上' },
      { type: 'input', field: 'marginBottomValue', title: '外边距-下' },
      { type: 'input', field: 'marginLeftValue', title: '外边距-左' },
      { type: 'input', field: 'marginRightValue', title: '外边距-右' },
      { type: 'input', field: 'paddingTop', title: '内边距-上' },
      { type: 'input', field: 'paddingBottom', title: '内边距-下' },
      { type: 'input', field: 'paddingLeft', title: '内边距-左' },
      { type: 'input', field: 'paddingRight', title: '内边距-右' },
      {
        type: 'select',
        field: 'flexDirection',
        title: '排列方向',
        options: [
          { label: '水平', value: 'row' },
          { label: '垂直', value: 'column' }
        ]
      },
      {
        type: 'select',
        field: 'justifyValue',
        title: '水平排列',
        options: [
          { label: '默认', value: 'none' },
          { label: '左', value: 'flex-start' },
          { label: '中', value: 'center' },
          { label: '右', value: 'flex-end' },
          { label: '等间距', value: 'space-around' },
          { label: '等间距(边界)', value: 'space-between' },
        ],
      },
      {
        type: 'select',
        field: 'alignValue',
        title: '垂直排列',
        options: [
          { label: '默认', value: 'none' },
          { label: '上', value: 'flex-start' },
          { label: '中', value: 'center' },
          { label: '下', value: 'flex-end' },
          { label: '等间距', value: 'space-around' },
          { label: '等间距(边界)', value: 'space-between' },
        ],
      },
      { type: 'switch', field: 'scrollX', title: '滚动条-横向' },
      { type: 'switch', field: 'scrollY', title: '滚动条-纵向' },
      {
        type: 'select', field: 'scrollShow', title: '是否显示滚动条',
        options: [
          { label: '显示', value: true },
          { label: '隐藏', value: false }
        ]
      },
      {
        type: 'select', field: 'hiddenInit', title: '是否显示',
        options: [
          { label: '显示', value: false },
          { label: '隐藏', value: true }
        ]
      },
      { type: 'input', field: 'hiddenKey', title: '隐藏属性(对应权限的code)' },
      {
        type: "yesStruct",
        field: "hidderScript",
        title: "隐藏脚本",
        info: "return true / return false",
        props: {
          valueType: 'function'
        }
      },
      {
        type: "yesStruct",
        field: "disabledScript",
        title: "禁用脚本",
        info: "return true / return false",
        props: {
          valueType: 'function'
        }
      },
      {
        type: 'select', field: 'backImg', title: '背景图片',
        options: [
          { label: '无', value: false },
          { label: '有', value: true }
        ],
        control: [
          {
            value: false,
            rule: [
            ]
          },
          {
            value: true,
            rule: [
              {
                type: 'input',
                field: 'backImgUrl',
                title: '图片地址'
              },
              {
                type: 'select', field: 'backShowType', title: '渲染方式',
                options: [
                  { label: '平铺', value: 'repeat' },
                  { label: '拉伸', value: 'no-repeat' }
                ],
                control: [
                  {
                    value: 'repeat',
                    rule: [
                    ]
                  },
                  {
                    value: 'no-repeat',
                    rule: [
                      {
                        type: 'select', field: 'coverType', title: '裁剪方式',
                        options: [
                          { label: '裁取', value: 'cover' },
                          { label: '铺满', value: 'all' }
                        ]
                      }
                    ]
                  }
                ]
              },
            ]
          },
        ]
      },
      {
        type: "select",
        field: "initClass",
        title: "标准样式",
        options: getOptions(),
      },
      {
        type: "input",
        field: "defealtClass",
        title: "自定义类",
        props: {
          type: "textarea",
          rows: 8,
        },
      },
      {
        type: "input",
        field: "definedCss",
        title: "自定义样式",
        props: {
          type: "textarea",
          rows: 8,
        },
      },
      {
        type: "yesStruct",
        field: "clickEvent",
        title: "点击事件",
        props: {
          valueType: 'function'
        }
      },
      {
        type: 'select',
        field: 'preventClick',
        title: '是否阻止用户重复点击',
        info: '手机端属性',
        options: [
          { label: '否', value: false },
          { label: '是', value: true }
        ],
      },
      { type: 'input', field: 'preventTime', title: '阻止用户重复点击时间(ms)' }
    ];
  }
};
