import { createName } from "../../utils";
const label = "弹出窗口";
const name = "yes-dialog";

export default {
    icon: "yes-icon-dialog",
    label,
    name,
    mask: false,
    rule() {
        return {
            type: name,
            name: "",
            children: [],
            props: {
                cancelText: "取 消",
                confirmText: "确 定",
                closeOnClickModal: false,
                closeOnPressEscape: false
            }
        };
    },
    children: "yes-row-col",
    props() {
        return [
            createName(),
            {
                type: "input",
                field: "title",
                title: "标题"
            },
            {
                type: "input",
                field: "width",
                title: "宽度"
            },
            {
                type: "input",
                field: "height",
                title: "高度"
            },
            {
                type: "input",
                field: "maxHeight",
                title: "弹窗body最大高度"
            },
            {
                type: "input",
                field: "topHeight",
                title: "弹窗距离顶部距离"
            },
            {
                type: "input",
                field: "cancelText",
                title: "取消按钮文本"
            },
            {
                type: "input",
                field: "confirmText",
                title: "确定按钮文本"
            },
            {
                type: "input",
                field: "app",
                title: "应用编号"
            },
            {
                type: "switch",
                field: "isShowButton",
                title: "是否显示弹窗按钮"
            },
            {
                type: "switch",
                field: "closeOnClickModal",
                title: "是否可以通过点击 modal 关闭 Dialog"
            },
            {
                type: "switch",
                field: "closeOnPressEscape",
                title: "是否可以通过按下 ESC 关闭 Dialog"
            },
            {
                type: "input",
                field: "code",
                title: "组件编号"
            },
            {
                type: "yesStruct",
                field: "closeEvent",
                title: "关闭弹窗点击事件脚本（取消按钮及弹窗关闭图标时触发）",
                props: {
                    valueType: "function"
                }
            },
            {
                type: "yesStruct",
                field: "clickEvent",
                title: "弹窗确定按钮点击事件(需先开放弹窗按钮)",
                props: {
                    valueType: "function"
                }
            }
        ];
    }
};
